import "core-js/stable";
import "regenerator-runtime/runtime";
import "@fortawesome/fontawesome-free/js/all";

import Rails from "@rails/ujs";
import "@hotwired/turbo-rails";
import * as ActiveStorage from "@rails/activestorage";
import "channels";

Rails.start();
ActiveStorage.start();

// Stimulus
import "controllers";

// Bootstrap
import "bootstrap";
import "../../stylesheets/my/application.scss";

// Images
const images = require.context("../../images/my", true); // eslint-disable-line no-unused-vars

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
